export const ordenarMenu = (a, b) => {
  if (a.orden < b.orden) {
    return -1;
  } else if (a.orden > b.orden) {
    return 1;
  } else {
    return 0;
  }
};

export const ordenarLista = (a, b) => {
  if (a.codigo_lista < b.codigo_lista) {
    return -1;
  } else if (a.codigo_lista > b.codigo_lista) {
    return 1;
  } else {
    return 0;
  }
};

export const b64DecodeUnicode = (str) => {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
};

export const validaEmail = (email) => {
  return email.match(
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
  );
};

export const validaPass = (pass) => {
  /*
  6 tamaño mínimo
  1 mayúscula mínimo
  1 minúscula mínimo
  1 número mínimo
  1 símbolo mínimo(*+-_.:,;!¡?¿$&#)
  */

  let regexp =
    /^(?=(?:[^a-z]*[a-z]){1,})(?=(?:[^A-Z]*[A-Z]){1,})(?=(?:[^0-9]*[0-9]){1,})(?=(?:.*[\*\+\-\_\.\:\,\;\!\¡\?\¿\$\&\#]){1,}).{6,}$/;

  return regexp.test(pass);
};

export const obtenerValorParametro = (
  lista_parametros,
  codigo_parametro,
  codigo_origen
) => {
  let parametros_filtrados;
  let valor_gen;
  let valor_esp;
  if (
    lista_parametros !== undefined &&
    lista_parametros !== null &&
    lista_parametros.length > 0
  ) {
    //Filtramos la lista
    parametros_filtrados = lista_parametros.filter(
      (parametro) =>
        (parametro.modulo === codigo_origen &&
          parametro.clave === codigo_parametro) ||
        (parametro.modulo === "GEN" && parametro.clave === codigo_parametro)
    );
    //Si existen valores
    if (
      parametros_filtrados !== undefined &&
      parametros_filtrados !== null &&
      parametros_filtrados.length > 0
    ) {
      //Si solo existe uno lo retornamos
      if (parametros_filtrados.length === 1) {
        return parametros_filtrados[0].valor;
      } else {
        //Si existen más  recorremos el array
        parametros_filtrados.forEach(function (value, index, array) {
          if (
            value.modulo === codigo_origen &&
            value.clave === codigo_parametro
          ) {
            //Seteamos el valor especifico
            valor_esp = value.valor;
          }
        });
        return valor_esp;
      }
    } else {
      // Si no encuentra valores retorna null
      return null;
    }
  }
};
