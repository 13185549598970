import {
  FETCH_P_PERFILES_IMAGENES,
  GET_P_PERFILES_IMAGENES,
} from "../actions/types.js";

const initialState = {
  imagenesPerfiles: [],
  isLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_P_PERFILES_IMAGENES:
      return {
        ...state,
        imagenesPerfiles: [],
        isLoading: true,
      };
    case GET_P_PERFILES_IMAGENES:
      return {
        ...state,
        isLoading: false,
        imagenesPerfiles: action.payload,
      };
    default:
      return state;
  }
}
