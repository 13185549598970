import {
  GET_TIPOS_EXPEDIENTES,
  GET_TIPOS_EXPEDIENTES_CAMPOS,
  GET_EXPEDIENTES,
  FETCH_TIPOS_EXPEDIENTES,
  FETCH_TIPOS_EXPEDIENTES_CAMPOS,
  FETCH_EXPEDIENTES,
  SEND_EXPEDIENTE,
  SEND_EXPEDIENTE_SUCCESS,
  GET_LINEAS_EXPEDIENTE,
  GET_LINEAS_EXPEDIENTE_SUCCESS,
  GET_OBSERVACIONES_EXPEDIENTE,
  GET_OBSERVACIONES_EXPEDIENTE_SUCCESS,
  SEND_OBSERVACIONES_EXPEDIENTE,
  SEND_OBSERVACIONES_EXPEDIENTE_SUCCESS,
  MARCAR_LEIDOS,
  MARCAR_LEIDOS_SUCCESS,
  DELETE_CONVERSATION,
  DELETE_CONVERSATION_SUCCESS,
  ARCHIVE_CONVERSATION,
  ARCHIVE_CONVERSATION_SUCCESS,
  GET_ARCHIVED_CONVERSATIONS,
  GET_ARCHIVED_CONVERSATIONS_SUCCESS,
  GET_DELETED_CONVERSATIONS,
  GET_DELETED_CONVERSATIONS_SUCCESS,
} from "../actions/types";

const initialState = {
  tipos_expedientes: [],
  tipos_expedientes_campos: [],
  expedientes: [],
  archived_conversations: [],
  deleted_conversations: [],
  observaciones_expediente: [],
  lineas_expediente: [],
  isLoading: false,
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_TIPOS_EXPEDIENTES:
      return {
        ...state,
        isLoading: true,
        tipos_expedientes: [],
      };
    case GET_TIPOS_EXPEDIENTES:
      return {
        ...state,
        isLoading: false,
        tipos_expedientes: action.payload,
      };
    case FETCH_TIPOS_EXPEDIENTES_CAMPOS:
      return {
        ...state,
        isLoading: true,
        tipos_expedientes_campos: [],
      };
    case GET_TIPOS_EXPEDIENTES_CAMPOS:
      return {
        ...state,
        isLoading: false,
        tipos_expedientes_campos: action.payload,
      };
    case FETCH_EXPEDIENTES:
      return {
        ...state,
        isLoading: true,
        expedientes: [],
      };
    case GET_EXPEDIENTES:
      return {
        ...state,
        isLoading: false,
        expedientes: action.payload,
      };
    //Nuevos de la App
    case SEND_EXPEDIENTE:
      return { ...state, loading: true };
    case SEND_EXPEDIENTE_SUCCESS:
      return {
        ...state,
        loading: false,
        expedientes: [...state.expedientes, action.payload.expediente],
      };
    case GET_LINEAS_EXPEDIENTE:
      return { ...state, lineas_expediente: [], loading: true };
    case GET_LINEAS_EXPEDIENTE_SUCCESS:
      return {
        ...state,
        lineas_expediente: action.payload.lineas_expediente,
        loading: false,
      };
    case GET_OBSERVACIONES_EXPEDIENTE:
      return { ...state, observaciones_expediente: [], loading: true };
    case GET_OBSERVACIONES_EXPEDIENTE_SUCCESS:
      return {
        ...state,
        observaciones_expediente: action.payload,
        loading: false,
      };
    case SEND_OBSERVACIONES_EXPEDIENTE:
      return { ...state, loading: true };
    case SEND_OBSERVACIONES_EXPEDIENTE_SUCCESS:
      return {
        ...state,
        observaciones_expediente: [
          ...state.observaciones_expediente,
          action.payload.observacion_expediente,
        ],
        loading: false,
      };

    case MARCAR_LEIDOS:
      return { ...state, loading: true };
    case MARCAR_LEIDOS_SUCCESS:
      return { ...state };
    case DELETE_CONVERSATION:
      return { ...state, loading: true };
    case ARCHIVE_CONVERSATION_SUCCESS:
    case DELETE_CONVERSATION_SUCCESS:
      let exp = state.expedientes.filter(
        (expediente) =>
          Number.parseInt(expediente.numero_expediente) !==
          Number.parseInt(action.payload.numero_expediente)
      );
      return {
        ...state,
        isLoading: false,
        expedientes: exp,
      };

    case ARCHIVE_CONVERSATION:
    case GET_ARCHIVED_CONVERSATIONS:
    case GET_DELETED_CONVERSATIONS:
      return { ...state, loading: true };
    case GET_ARCHIVED_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        archived_conversations: action.payload,
      };
    case GET_DELETED_CONVERSATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        deleted_conversations: action.payload,
      };
    default:
      return state;
  }
}
