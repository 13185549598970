import {
  GET_PRECIO,
  GET_PRECIO_SUCCESS,
  GET_PRECIO_FAIL,
  LIMPIAR_PRECIOS,
} from "../actions/types.js";

const initialState = {
  precio: {},
  locale: "es-ES",
  moneda: "EUR",
};

export default function reducer(state = initialState, action) {
  let lista_precios;
  switch (action.type) {
    case LIMPIAR_PRECIOS:
      return {
        ...state,
        precio: {},
      };

    case GET_PRECIO:
      lista_precios = JSON.parse(JSON.stringify(state.precio));

      lista_precios[action.codigo_articulo] = {
        data: {},
        isLoading: true,
        error: false,
      };
      return {
        ...state,
        precio: lista_precios,
      };
    case GET_PRECIO_FAIL:
      lista_precios = JSON.parse(JSON.stringify(state.precio));

      lista_precios[action.codigo_articulo].data = {};
      lista_precios[action.codigo_articulo].isLoading = false;
      lista_precios[action.codigo_articulo].error = true;
      return {
        ...state,
        precio: lista_precios,
      };
    case GET_PRECIO_SUCCESS:
      lista_precios = JSON.parse(JSON.stringify(state.precio));

      lista_precios[action.codigo_articulo].data = action.payload;
      lista_precios[action.codigo_articulo].isLoading = false;
      lista_precios[action.codigo_articulo].error = false;

      let locale = "";
      switch (lista_precios[action.codigo_articulo].data.divisa_precio) {
        case "COP":
          locale = "es-CO";
          break;

        case "EUR":
          locale = "es-ES";
          break;

        case "BOP":
          locale = "es-BO";
          break;

        case "USD":
          locale = "en-US";
          break;

        case "DOP":
          locale = "es-DO";
          break;

        case "GBP":
          locale = "en-GB";
          break;
        default:
          locale = "es-ES";
      }
      
      return {
        ...state,
        precio: lista_precios,
        locale: locale,
        moneda: lista_precios[action.codigo_articulo].data.divisa_precio,
      };
    default:
      return state;
  }
}
