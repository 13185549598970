import {
  FETCH_EX_GRUPOS_PERFILES,
  GET_EX_GRUPOS_PERFILES,
  SET_BUSQUEDA_GRUPO,
  SET_EX_GRUPO_SELECCIONADO,
  UNSET_EX_GRUPO_SELECCIONADO,
  GET_MAS_GRUPOS,
  FETCH_MAS_GRUPOS
} from "../actions/types.js";

const initialState = {
  grupos: {
    grupos: [],
    grupos_total: 0,
    grupos_siguiente: "",
    grupos_anterior: "",
  },
  grupo_seleccionado: null,
  grupos_pagina: 12,
  isLoading: false,
};

export default function (state = initialState, action) {
  let grupos_nuevo;
  let mas_grupos;

  switch (action.type) {
    case FETCH_MAS_GRUPOS:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EX_GRUPOS_PERFILES:
      return {
        grupos: {
          grupos: [],
          grupos_total: 0,
          grupos_siguiente: "",
          grupos_anterior: "",
        },
        grupo_seleccionado: state.grupo_seleccionado,
        grupos_pagina: 12,
        isLoading: true,
      };
    case GET_EX_GRUPOS_PERFILES:
      grupos_nuevo = JSON.parse(JSON.stringify(state.grupos));
      grupos_nuevo.grupos = action.payload.results;
      grupos_nuevo.grupos_total = null;
      grupos_nuevo.grupos_siguiente = null;
      grupos_nuevo.grupos_anterior = null;
      return {
        ...state,
        grupos: grupos_nuevo,
        grupos_pagina: 12,
        isLoading: false,
      };
    case SET_BUSQUEDA_GRUPO:
      return {
        ...state,
        isLoading: false,
        grupos: action.payload,
        grupo_seleccionado: null
      };
    case SET_EX_GRUPO_SELECCIONADO:
      return {
        ...state,
        grupo_seleccionado: action.payload,
      };
    case UNSET_EX_GRUPO_SELECCIONADO:
      return {
        ...state,
        grupos: {
          grupos: [],
          grupos_total: 0,
          grupos_siguiente: "",
          grupos_anterior: "",
        },
        grupo_seleccionado: null,
      };
    case GET_MAS_GRUPOS:
      mas_grupos = JSON.parse(JSON.stringify(state.grupos));
      mas_grupos.grupos = [
        ...mas_grupos.grupos,
        ...action.payload.results,
      ];
      mas_grupos.grupos_siguiente = action.payload.next;
      mas_grupos.grupos_anterior = action.payload.previous;
      return {
        ...state,
        grupos: mas_grupos,
        isLoading: false,
      };
    default:
      return state;
  }
}