import {
  FETCH_EX_TRATAMIENTOS,
  GET_EX_TRATAMIENTOS,
  FETCH_TRATAMIENTOS_DISPONIBLES,
  FETCH_TRATAMIENTOS_DISPONIBLES_FAIL,
  GET_TRATAMIENTOS_DISPONIBLES,
  SET_LISTA_TRATAMIENTOS1_COMPLETA,
  UNSET_LISTA_TRATAMIENTOS1_COMPLETA,
  SET_LISTA_TIPOS_TRATAMIENTO1,
  SET_LISTA_TRATAMIENTOS1,
  UNSET_LISTA_TRATAMIENTOS1,
  SET_LISTA_TRATAMIENTOS2_COMPLETA,
  SET_LISTA_TRATAMIENTOS2,
  SET_TIPOS_TRATAMIENTO2,
  SET_VALORES_TRATAMIENTO2,
  UNSET_LISTA_TRATAMIENTOS2,
  SET_VALORES_EMPAQUETADO,
  UNSET_LISTA_EMPAQUETADO,
  UNSET_LISTA_LONGITUDES,
  SET_LISTA_LONGITUDES,
  UNSET_LISTAS_TRATAMIENTOS
} from "../actions/types.js";

const initialState = {
  tratamientos: [],
  lista_tratamientos_disponibles: [],
  lista_aleaciones: [], // viene de la respuesta de la llamada a lisa a recuperar tratamientos para el perfil seleccionado
  lista_longitudes: [],
  lista_tratamientos_1_completa: [],
  lista_tipos: [],
  lista_tratamientos_1: [],
  lista_tratamientos_2_completa: [],
  lista_tratamientos_2: [],
  lista_tipos_tratamiento_2: [],
  lista_valores_tratamiento_2: [],
  lista_valores_empaquetado: [],
  isLoading: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case FETCH_EX_TRATAMIENTOS:
      return {
        ...state,
        tratamientos: [],
        isLoading: true,
      };
    case GET_EX_TRATAMIENTOS:
      return {
        ...state,
        isLoading: false,
        tratamientos: action.payload,
      };
    case FETCH_TRATAMIENTOS_DISPONIBLES:
      return {
        ...state,
        lista_tratamientos: [],
        isLoading: true,
      };
    case FETCH_TRATAMIENTOS_DISPONIBLES_FAIL:
      return {
        ...state,
        isLoading: false,
      };
    case GET_TRATAMIENTOS_DISPONIBLES:
      return {
        ...state,
        isLoading: false,
        lista_aleaciones: action.payload.lista_aleaciones,
        lista_longitudes: action.payload.lista_longitudes,
        lista_tratamientos_disponibles: action.payload.listado_completo,
        lista_tipos: [] 
      };
    case SET_LISTA_TRATAMIENTOS1_COMPLETA:
      return {
        ...state,
        isLoading: false,
        lista_tratamientos_1_completa: action.payload
      };
    case UNSET_LISTA_TRATAMIENTOS1_COMPLETA:
      return {
        ...state,
        lista_tipos: [],
        lista_tratamientos_1: [],
        lista_valores_empaquetado: [],
      };
    case SET_LISTA_TIPOS_TRATAMIENTO1:
      return {
        ...state,
        isLoading: false,
        lista_tipos: action.payload
      };
    case SET_LISTA_TRATAMIENTOS1:
      return {
        ...state,
        isLoading: false,
        lista_tratamientos_1: action.payload
      };
    case UNSET_LISTA_TRATAMIENTOS1:
      return {
        ...state,
        lista_tipos: [],
        lista_tratamientos_1: [],
        lista_valores_empaquetado: [],
        lista_tratamientos_2: [],
        lista_tipos_tratamiento_2: [],
        lista_valores_tratamiento_2: [],
      };
    case SET_LISTA_TRATAMIENTOS2_COMPLETA:
      return {
        ...state,
        isLoading: false,
        lista_tratamientos_2_completa: action.payload
      };
    case SET_LISTA_TRATAMIENTOS2:
      return {
        ...state,
        isLoading: false,
        lista_tratamientos_2: action.payload
      };
    case SET_TIPOS_TRATAMIENTO2:
      return {
        ...state,
        isLoading: false,
        lista_tipos_tratamiento_2: action.payload
      };
    case SET_VALORES_TRATAMIENTO2:
      return {
        ...state,
        isLoading: false,
        lista_valores_tratamiento_2: action.payload
      };
    case UNSET_LISTA_TRATAMIENTOS2:
      return {
        ...state,
        lista_tratamientos_2: [],
        lista_tipos_tratamiento_2: [],
        lista_valores_tratamiento_2: [],
        lista_valores_empaquetado: [],
      };
    case SET_VALORES_EMPAQUETADO:
      return {
        ...state,
        isLoading: false,
        lista_valores_empaquetado: action.payload
      };
    case UNSET_LISTA_EMPAQUETADO:
        return {
          ...state,
          lista_valores_empaquetado: [],
        };
    case SET_LISTA_LONGITUDES:
      return {
        ...state,
        isLoading: false,
        lista_longitudes: action.payload,
      };
    case UNSET_LISTA_LONGITUDES:
      return {
        ...state,
        lista_longitudes: [],
      };
    case UNSET_LISTAS_TRATAMIENTOS:
      return {
        ...state,
        tratamientos: [],
        lista_tratamientos_disponibles: [],
        lista_aleaciones: [], 
        lista_longitudes: [],
        lista_tratamientos_1_completa: [],
        lista_tipos: [],
        lista_tratamientos_1: [],
        lista_tratamientos_2_completa: [],
        lista_tratamientos_2: [],
        lista_tipos_tratamiento_2: [],
        lista_valores_tratamiento_2: [],
        lista_valores_empaquetado: []
      };
    default:
      return state;
  }
}
