import {
  FETCH_EX_SUBSERIES_PERFILES,
  GET_EX_SUBSERIES_PERFILES,
  SET_BUSQUEDA_SUBSERIE,
  SET_EX_SUBSERIE_SELECCIONADA,
  UNSET_EX_SUBSERIE_SELECCIONADA,
  GET_MAS_SUBSERIES,
  FETCH_MAS_SUBSERIES,
} from "../actions/types.js";

const initialState = {
  subseries: {
    subseries: [],
    subseries_total: 0,
    subseries_siguiente: "",
    subseries_anterior: "",
  },
  subserie_seleccionada: null,
  subseries_pagina: 12,
  isLoading: false,
};

export default function (state = initialState, action) {
  let subseries_nuevo;
  let mas_subseries;

  switch (action.type) {
    case FETCH_MAS_SUBSERIES:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EX_SUBSERIES_PERFILES:
      return {
        subseries: {
          subseries: [],
          subseries_total: 0,
          subseries_siguiente: "",
          subseries_anterior: "",
        },
        subserie_seleccionada: state.subserie_seleccionada,
        subseries_pagina: 12,
        isLoading: true,
      };
    case GET_EX_SUBSERIES_PERFILES:
      subseries_nuevo = JSON.parse(JSON.stringify(state.subseries));
      subseries_nuevo.subseries = action.payload.results;
      subseries_nuevo.subseries_total = null;
      subseries_nuevo.subseries_siguiente = null;
      subseries_nuevo.subseries_anterior = null;
      return {
        ...state,
        subseries: subseries_nuevo,
        subseries_pagina: 12,
        isLoading: false,
      };
    case SET_BUSQUEDA_SUBSERIE:
      return {
        ...state,
        isLoading: false,
        subseries: action.payload,
        subserie_seleccionada: null
      };
    case SET_EX_SUBSERIE_SELECCIONADA:
      return {
        ...state,
        subserie_seleccionada: action.payload,
      };
    case UNSET_EX_SUBSERIE_SELECCIONADA:
      return {
        ...state,
        subseries: {
          subseries: [],
          subseries_total: 0,
          subseries_siguiente: "",
          subseries_anterior: "",
        },
        subserie_seleccionada: null,
      };
    case GET_MAS_SUBSERIES:
      mas_subseries = JSON.parse(JSON.stringify(state.subseries));
      mas_subseries.subseries = [
        ...mas_subseries.subseries,
        ...action.payload.results,
      ];
      mas_subseries.subseries_siguiente = action.payload.next;
      mas_subseries.subseries_anterior = action.payload.previous;
      return {
        ...state,
        subseries: mas_subseries,
        isLoading: false,
      };
    default:
      return state;
  }
}
