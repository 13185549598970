import React, { Component } from "react";
import { connect } from "react-redux";

export class Spinner extends Component {
  render() {
    return (
      <div className="spinner-container">
        <div className="spinner"></div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Spinner);
