import axios from "../axiosConfig";
import { tokenConfig } from "./auth";

import { GET_PRECIO, GET_PRECIO_SUCCESS, GET_PRECIO_FAIL, LIMPIAR_PRECIOS  } from "./types";

// GET ARTICULO
export const getPrecio =
  (
    codigo_articulo,
    cantidad_pedida = 1,
    id_pedido_seleccionado = "",
    domicilio_envio = ""
  ) =>
  (dispatch, getState) => {
    dispatch({
      type: GET_PRECIO,
      codigo_articulo: codigo_articulo,
    });
    axios
      .get(
        `/precio/?codigo_articulo=${codigo_articulo}&cant=${cantidad_pedida}&id_pedido_seleccionado=${id_pedido_seleccionado}&domicilio_envio=${domicilio_envio}`,
        tokenConfig(getState)
      )
      .then((res) => {
        dispatch({
          type: GET_PRECIO_SUCCESS,
          codigo_articulo: codigo_articulo,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log("Precio:", err.response.data);
        dispatch({
          type: GET_PRECIO_FAIL,
          codigo_articulo: codigo_articulo,
        });
      });
  };
//LIMPIAR PRECIO
export const limpiarPrecio = () => (dispatch, getState) => {
  dispatch({
    type: LIMPIAR_PRECIOS,
  });
};
