import React, { Component, Fragment, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import { getMenus } from "../../actions/portal";

import { ordenarMenu } from "../../helpers/funciones";

import { Helmet } from "react-helmet";

import Loader from "../layout/Loader";
import Conversaciones from "../expedientes/Conversaciones";

const Articulos = lazy(() => import("../ecommerce/Articulos"));
const Articulo = lazy(() => import("../ecommerce/Articulo"));
const Cuenta = lazy(() => import("../usuarios/Cuenta"));
const Informes = lazy(() => import("../ecommerce/Informes"));
const Resumen = lazy(() => import("../ecommerce/Resumen"));
const Login = lazy(() => import("../usuarios/Login"));
const Registro = lazy(() => import("../usuarios/Registro"));
const Logout = lazy(() => import("../usuarios/Logout"));
const Recuperar = lazy(() => import("../usuarios/Recuperar"));
const PrivateRoute = lazy(() => import("./PrivateRoute"));
const ListasCompra = lazy(() => import("../ecommerce/ListasCompra"));
const Inicio = lazy(() => import("./Inicio"));
const Estatico = lazy(() => import("./Estatico"));
const Contacto = lazy(() => import("./Contacto"));
const PedidosAnteriores = lazy(() => import("../ecommerce/PedidosAnteriores"));
const PedidosPendientes = lazy(() => import("../ecommerce/PedidosPendientes"));
const PedidoHabitual = lazy(() => import("../ecommerce/PedidoHabitual"));
const NovedadesOfertas = lazy(() => import("../ecommerce/NovedadesOfertas"));
const Widgets = lazy(() => import("../widgets/Widgets"));
const RegistroCompleto = lazy(() => import("../usuarios/RegistroCompleto"));
const Expedientes = lazy(() => import("../expedientes/Expedientes"));
const Pedido = lazy(() => import("../ecommerce/Pedido"));
const Home = lazy(() => import("../ecommerce/Home"));
const Wompi = lazy(() => import("../ecommerce/Wompi"));
const Modular = lazy(() => import("./Modular"));
const ConfigCookies = lazy(() => import("./ConfigCookies"));
const RegistroHijo = lazy(() => import("../usuarios/RegistroHijo"));
const EfacturaVentas = lazy(() => import("../efactura/EfacturaVentas"));
const EfacturaCompras = lazy(() => import("../efactura/EfacturaCompras"));
const ArticulosExtrusion = lazy(() => import("../ecommerce/ArticulosExtrusion"));

export class ControladorRutas extends Component {
  static propTypes = {
    menus: PropTypes.array.isRequired,
    portal: PropTypes.object.isRequired,
    getMenus: PropTypes.func.isRequired,
  };

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.portal !== this.props.portal &&
      this.props.portal.descripcion !== undefined
    ) {
      this.props.getMenus();
    }
  }

  componenteDeRuta = (ruta) => ruta[0].toUpperCase() + ruta.slice(1);

  iterar_menus = (menu) => {
    if (menu.programa !== null) {
      if (menu.publico === "S") {
        switch (menu.programa) {
          case "articulos":
            return (
              <Route
                key={menu.programa}
                exact
                path="/articulos/:estadistico?/:familia?/:descripcion?"
                component={Articulos}
              />
            );
          case "home":
            return (
              <Route key={menu.programa} exact path="/home" component={Home} />
            );
	  case "articulos_extrusion":
            return(
              <Route
                key={menu.programa}
                exact
                path="/articulos_extrusion"
                component={ArticulosExtrusion}
              />
            );
          case "login":
            return (
              <Route
                key={menu.programa}
                exact
                path="/login"
                component={Login}
              />
            );
          case "registro":
            return (
              <Route
                key={menu.programa}
                exact
                path="/registro"
                component={Registro}
              />
            );
          case "contacto":
            return (
              <Route
                key={menu.programa}
                exact
                path="/contacto"
                component={Contacto}
              />
            );
          case "registroCompleto":
            return (
              <Route
                key={menu.programa}
                exact
                path="/registroCompleto"
                component={RegistroCompleto}
              />
            );
          case "novedadesOfertas":
            return (
              <Route
                key={menu.programa}
                exact
                path="/novedadesOfertas"
                component={NovedadesOfertas}
              />
            );
          case "pedido":
            return (
              <Route
                key={menu.programa}
                exact
                path="/pedido"
                component={Pedido}
              />
            );

          default:
            return "";
        }
      } else {
        switch (menu.programa) {
          case "articulos":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/articulos/:estadistico?/:familia?/:descripcion?"
                component={Articulos}
              />
            );
          case "home":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/home"
                component={Home}
              />
            );
          case "contacto":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/contacto"
                component={Contacto}
              />
            );
          case "listasCompra":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/listasCompra"
                component={ListasCompra}
              />
            );
          case "pedidoHabitual":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/pedidoHabitual"
                component={PedidoHabitual}
              />
            );
          case "pedidosAnteriores":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/pedidosAnteriores"
                component={PedidosAnteriores}
              />
            );
          case "pedidosPendientes":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/pedidosPendientes"
                component={PedidosPendientes}
              />
            );
          case "novedadesOfertas":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/novedadesOfertas"
                component={NovedadesOfertas}
              />
            );
          case "informes":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/informes"
                component={Informes}
              />
            );
          case "widgets":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/widgets"
                component={Widgets}
              />
            );
          case "expedientes":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/expedientes"
                component={Expedientes}
              />
            );
          case "conversaciones":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/conversaciones"
                component={Conversaciones}
              />
            );
          case "pedido":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/pedido"
                component={Pedido}
              />
            );
          case "cuenta":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/cuenta"
                component={Cuenta}
              />
            );
          case "logout":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/logout"
                component={Logout}
              />
            );
          case "registroHijos":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/registrohijos"
                component={RegistroHijo}
              />
            );
          case "efacturaVentas":
            return (
              <PrivateRoute
                key={menu.programa}
                exact
                path="/efacturaVentas"
                component={EfacturaVentas}
              />
            );
          case "efacturaCompras":
            return (
              <PrivateRoute
                key="efacturasCompras"
                exact
                path="/efacturaCompras"
                component={EfacturaCompras}
              />
            );
	  case "articulos_extrusion":
            return(
              <Route
                key={menu.programa}
                exact
                path="/articulos_extrusion"
                component={ArticulosExtrusion}
              />
            );
          default:
            return "";
        }
      }
    } else {
      if (menu.hijos.length > 0) {
        return menu.hijos.map(this.iterar_menus);
      } else {
        return "";
      }
    }
  };

  render() {
    const menus = this.props.menus;
    const portal = this.props.portal;

    let Rutainicio;
    let Componenteinicio;
    let menu_articulos;

    if (menus !== undefined && menus.length > 0) {
      menus.sort(ordenarMenu);
      Rutainicio = menus.find(
        (menu) => menu.activo === "S" && menu.programa !== null
      );

      menu_articulos = menus.find((menu) => menu.programa === "articulos");

      Componenteinicio = Rutainicio.programa;
    } else {
      Componenteinicio = null;
      menu_articulos = null;
    }

    return (
      <Fragment>
        {menus !== undefined &&
        menus.length > 0 &&
        portal.descripcion !== undefined ? (
          <Fragment>
            <Helmet>
              <meta charSet="utf-8" />
              <meta
                name="description"
                content={
                  portal.parametros_seo.descripcion !== undefined
                    ? portal.parametros_seo.descripcion
                    : portal.descripcion !== undefined
                    ? portal.descripcion
                    : "Portal Ecommerce"
                }
              />
              <meta
                name="keywords"
                content={
                  portal.parametros_seo.palabras_clave !== undefined
                    ? portal.parametros_seo.palabras_clave
                    : ""
                }
              />
              <title>
                {portal.parametros_seo.titulo !== undefined
                  ? portal.parametros_seo.titulo
                  : portal.descripcion !== undefined
                  ? portal.descripcion
                  : "Portal Ecommerce"}
              </title>
              {portal.favicon !== undefined && portal.favicon !== null ? (
                <link
                  rel="icon"
                  type="image/png"
                  href={"data:image/png;base64, " + portal.favicon}
                  sizes="16x16"
                />
              ) : (
                ""
              )}
            </Helmet>
            <Suspense fallback={<Loader />}>
              <Switch>
                {Componenteinicio !== null ? (
                  <Route
                    key="inicio"
                    exact
                    path="/"
                    render={(props) => (
                      <Inicio {...props} componente={Componenteinicio} />
                    )}
                  />
                ) : (
                  ""
                )}

                {menus.map(this.iterar_menus)}

                <Route
                  key="estatico"
                  exact
                  path="/estatico/:componente"
                  component={Estatico}
                />
                <Route
                  key="modular"
                  exact
                  path="/modular/:modulo"
                  component={Modular}
                />
                <Route
                  key="recuperar"
                  exact
                  path="/recuperar"
                  component={Recuperar}
                />
                <PrivateRoute
                  key="resumen"
                  exact
                  path="/resumen"
                  component={Resumen}
                />
                <PrivateRoute
                  key="wompi"
                  exact
                  path="/wompi"
                  component={Wompi}
                />
                <Route
                  key="config_cookies"
                  exact
                  path="/configurarCookies"
                  component={ConfigCookies}
                />

                {menu_articulos !== undefined &&
                menu_articulos !== null &&
                menu_articulos.publico === "S" ? (
                  <Route
                    key="articulo"
                    exact
                    path="/articulo/:id_articulo/:descrip_comercial"
                    component={Articulo}
                  />
                ) : (
                  <PrivateRoute
                    key="articulo"
                    exact
                    path="/articulo/:id_articulo/:descrip_comercial"
                    component={Articulo}
                  />
                )}
              </Switch>
            </Suspense>
          </Fragment>
        ) : (
          ""
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  menus: state.portal.menus,
  portal: state.portal.portal,
});

export default connect(mapStateToProps, { getMenus })(ControladorRutas);
