import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import {
  deleteLinea,
  nuevoPedido,
  setIdPedidoSeleccionado,
  getPedidoActivo,
} from "../../actions/pedidos";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import Imagen from "./Imagen";
import Precio from "./Precio";
import ImagenPerfil from "./ImagenPerfil";

export class Carrito extends Component {
  static propTypes = {
    pedido: PropTypes.object.isRequired,
    deleteLinea: PropTypes.func.isRequired,
    hash: PropTypes.string,
    invitado: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    moneda: PropTypes.string.isRequired,
    setIdPedidoSeleccionado: PropTypes.func.isRequired,
    nuevoPedido: PropTypes.func.isRequired,
    portal: PropTypes.object,
  };

  onClick = (e) => {
    if (!this.props.pedido_cargando) {
      e.preventDefault();
      this.props.deleteLinea(
        e.target.dataset.linea,
        this.props.hash,
        this.props.id_pedido_seleccionado
      );
    }
  };

  ocultarCarrito = (e) => {
    if (document.getElementsByClassName("tt-dropdown-obj active").length > 0) {
      document
        .getElementsByClassName("tt-dropdown-obj active")[0]
        .getElementsByClassName("tt-dropdown-menu")[0].style = "";

      document
        .getElementsByClassName("tt-dropdown-obj active")[0]
        .classList.remove("active");
    }
    if (document.getElementsByTagName("body").length > 0) {
      document
        .getElementsByTagName("body")[0]
        .classList.remove("tt-popup-dropdown");
    }
  };

  nuevoPedido = () => {
    this.props.setIdPedidoSeleccionado("");
    this.props.nuevoPedido();
    this.props.getPedidoActivo("", "S");
  };

  render() {
    return (
      <Fragment>
        <div className="tt-dropdown-menu">
          <div className="tt-mobile-add">
            <h6 className="tt-title">CARRITO</h6>
            <button className="tt-close">Cerrar</button>
          </div>
          <div className="tt-dropdown-inner">
            <div className="tt-cart-layout">
              <div className="tt-cart-content">
                <div className="info-ped-pres">
                  {this.props.pedido.lineas !== undefined &&
                  this.props.pedido.lineas.length > 0 &&
                  this.props.pedido.lineas.some(
                    (linea) => linea.tipo_linea === "P"
                  ) ? (
                    <div className="info-pedido">
                      <div>
                        <h3 className="titulo-carrito">Pedido</h3>
                      </div>
                      <div className="tt-cart-list">
                        {this.props.pedido.lineas === undefined ||
                        this.props.pedido.lineas.length === 0
                          ? ""
                          : this.props.pedido.lineas
                              .filter((linea) => {
                                if (linea.tipo_linea === "P") {
                                  return true;
                                } else if (linea.tipo_linea === "R") {
                                  return this.props.pedido.lineas.some(
                                    (linea_padre) =>
                                      (linea_padre.numero_linea =
                                        linea.numero_linea_origen &&
                                        linea_padre.tipo_linea === "P")
                                  );
                                } else return false;
                              })
                              .map((linea) => (
                                <div className="tt-item" key={linea.id}>
                                  {linea.tipo_articulo !== "F" ? (
                                    <Fragment>
                                      <Link
                                        to={
                                          this.props.portal.sector_empresa !==
                                            undefined &&
                                          this.props.portal.sector_empresa !==
                                            "" &&
                                          this.props.portal.sector_empresa ===
                                            "AL"
                                            ? "/"
                                            : "/articulo/" +
                                              linea.id_articulo +
                                              "/" +
                                              linea.descripcion
                                                .replaceAll("/", " ")
                                                .replaceAll(" ", "-")
                                                .replaceAll("%", "")
                                                .toLowerCase()
                                        }
                                      >
                                        <div className="tt-item-img">
                                          {this.props.portal.sector_empresa !==
                                            undefined &&
                                          this.props.portal.sector_empresa !==
                                            "" &&
                                          this.props.portal.sector_empresa ===
                                            "AL" ? (
                                            <ImagenPerfil
                                              codigo_perfil={linea.articulo.substr(
                                                0,
                                                5
                                              )}
                                              tipo="WEB"
                                            />
                                          ) : (
					    <Imagen
                                              codigo_articulo={linea.articulo}
                                              principal="S"
                                              numero_imagen={1}
                                            />
					  )}
                                        </div>
                                        <div className="tt-item-descriptions">
                                          <h2 className="tt-title">
                                            {linea.articulo}
                                          </h2>
                                          <ul className="tt-add-info">
                                            <li>{linea.descripcion}</li>
                                            <li className="ocultar">
                                              Pesentación:{" "}
                                              {linea.presentacion_pedido}
                                            </li>
                                          </ul>
                                          <div className="tt-quantity">
                                            {linea.sub_pres !== undefined &&
                                            linea.sub_pres !== null &&
                                            linea.sub_pres !== ""
                                              ? linea.sub_pres_cant +
                                                " X " +
                                                linea.sub_pres +
                                                " X "
                                              : linea.cantidad_pedida +
                                                " (" +
                                                linea.presentacion_pedido +
                                                ") X "}
                                          </div>
                                          <div className="tt-price">
                                            <div className="ocultar">
                                              <Precio
                                                codigo_articulo={linea.articulo}
                                                precio_manual={
                                                  linea.precio_venta
                                                }
                                              />
                                            </div>
                                            {linea.tipo_linea !== "R" ? (
                                              linea.precio_manual === "S" ? (
                                                <Precio
                                                  codigo_articulo={
                                                    linea.articulo
                                                  }
                                                  precio_manual={
                                                    linea.precio_venta
                                                  }
                                                />
                                              ) : (
                                                <span>
                                                  {
                                                    <Precio
                                                      codigo_articulo={
                                                        linea.articulo
                                                      }
                                                    />
                                                  }
                                                </span>
                                              )
                                            ) : (
                                              <b> 0,00 €</b>
                                            )}
                                          </div>
                                        </div>
                                      </Link>

                                      {linea.tipo_articulo !== "R" &&
                                      linea.tipo_articulo !== "T" &&
                                      linea.tipo_articulo !== "F" ? (
                                        <div className="tt-item-close">
                                          <a
                                            href="/#"
                                            onClick={this.onClick}
                                            data-linea={linea.id}
                                            className="tt-btn-close"
                                          >
                                            <span className="ocultar">
                                              Cerrar
                                            </span>
                                          </a>
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      {" "}
                                      <div className="tt-item-img">
                                        {this.props.portal.sector_empresa !==
                                          undefined &&
                                        this.props.portal.sector_empresa !==
                                          "" &&
                                        this.props.portal.sector_empresa ===
                                          "AL" ? (
                                          <ImagenPerfil
                                            codigo_perfil={linea.articulo.substr(
                                              0,
                                              5
                                            )}
                                            tipo="WEB"
                                          />
                                        ) : (
					  <Imagen
                                            codigo_articulo={linea.articulo}
                                            principal="S"
                                            numero_imagen={1}
                                          />
					)}
                                      </div>
                                      <div className="tt-item-descriptions">
                                        <h2 className="tt-title">
                                          {linea.articulo}
                                        </h2>
                                        <ul className="tt-add-info">
                                          <li>{linea.descripcion}</li>
                                          <li className="ocultar">
                                            Presentación:{" "}
                                            {linea.presentacion_pedido}
                                          </li>
                                        </ul>
                                        <div className="tt-quantity">
                                          {linea.cantidad_pedida +
                                            " (" +
                                            linea.presentacion_pedido +
                                            ") X "}
                                        </div>
                                        <div className="tt-price">
                                          {linea.precio_manual === "S" ? (
                                            <Precio
                                              codigo_articulo={linea.articulo}
                                              precio_manual={linea.precio_venta}
                                            />
                                          ) : (
                                            <span>
                                              <div className="ocultar">
                                                <Precio
                                                  codigo_articulo={
                                                    linea.articulo
                                                  }
                                                  precio_manual={
                                                    linea.precio_venta
                                                  }
                                                />
                                              </div>
                                              {" " +
                                                Intl.NumberFormat(
                                                  this.props.locale,
                                                  {
                                                    style: "currency",
                                                    currency: this.props.moneda,
                                                  }
                                                ).format(
                                                  Number.parseFloat(
                                                    linea.precio_venta /
                                                      linea.cantidad_pedida
                                                  ).toFixed(2)
                                                )}

                                              {this.props.precio[
                                                linea.articulo
                                              ] !== undefined &&
                                              !Array.isArray(
                                                this.props.precio[
                                                  linea.articulo
                                                ].data.impuesto_articulo
                                              ) &&
                                              this.props.precio[linea.articulo]
                                                .data.impuesto_articulo !== null
                                                ? " + " +
                                                  Number.parseInt(
                                                    this.props.precio[
                                                      linea.articulo
                                                    ].data.impuesto_articulo
                                                  ) +
                                                  "% IVA"
                                                : ""}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </Fragment>
                                  )}
                                </div>
                              ))}
                      </div>

                      <div className="tt-cart-total-row">
                        <div className="tt-cart-total-title">TOTAL:</div>
                        <div className="tt-cart-total-price">
                          {this.props.pedido.lineas === undefined ||
                          this.props.pedido.lineas.length === 0
                            ? 0
                            : Intl.NumberFormat(this.props.locale, {
                                style: "currency",
                                currency: this.props.moneda,
                              }).format(
                                Number.parseFloat(
                                  this.props.pedido.lineas
                                    .filter((linea) => linea.tipo_linea === "P")
                                    .map((linea) => {
                                      return Number.parseFloat(
                                        linea.precio_venta *
                                          linea.cantidad_pedida
                                      );
                                    })
                                    .reduce(
                                      (accumulator, currentValue) =>
                                        accumulator + currentValue
                                    )
                                ).toFixed(2)
                              )}
                        </div>
                      </div>
                      <div className="tt-cart-total-row">
                        <div className="tt-cart-total-title">TOTAL + IVA:</div>
                        <div className="tt-cart-total-price">
                          {this.props.pedido.lineas === undefined ||
                          this.props.pedido.lineas.length === 0
                            ? 0
                            : new Intl.NumberFormat(this.props.locale, {
                                style: "currency",
                                currency: this.props.moneda,
                              }).format(
                                Number.parseFloat(
                                  this.props.pedido.lineas
                                    .filter((linea) => linea.tipo_linea === "P")
                                    .map((linea) => {
                                      let impuesto_articulo = 0;
                                      if (
                                        this.props.precio[linea.articulo] !==
                                          undefined &&
                                        !Array.isArray(
                                          this.props.precio[linea.articulo].data
                                            .impuesto_articulo
                                        ) &&
                                        this.props.precio[linea.articulo].data
                                          .impuesto_articulo !== null
                                      ) {
                                        impuesto_articulo =
                                          Number.parseInt(
                                            this.props.precio[linea.articulo]
                                              .data.impuesto_articulo
                                          ) / 100;
                                      }

                                      return (
                                        Number.parseFloat(
                                          linea.precio_venta *
                                            linea.cantidad_pedida
                                        ) *
                                        (1 + impuesto_articulo)
                                      );
                                    })
                                    .reduce((accumulator, currentValue) => {
                                      return accumulator + currentValue;
                                    })
                                ).toFixed(2)
                              )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {this.props.pedido.lineas !== undefined &&
                  this.props.pedido.lineas.length > 0 &&
                  this.props.pedido.lineas.some(
                    (linea) => linea.tipo_linea === "O"
                  ) ? (
                    <div className="info-presupuesto">
                      <div>
                        <h3 className="titulo-carrito">Presupuesto</h3>
                      </div>
                      <div className="tt-cart-list">
                        {this.props.pedido.lineas === undefined ||
                        this.props.pedido.lineas.length === 0
                          ? ""
                          : this.props.pedido.lineas
                              .filter((linea) => {
                                if (linea.tipo_linea === "O") {
                                  return true;
                                } else if (linea.tipo_linea === "R") {
                                  return this.props.pedido.lineas.some(
                                    (linea_padre) =>
                                      (linea_padre.numero_linea =
                                        linea.numero_linea_origen &&
                                        linea_padre.tipo_linea === "O")
                                  );
                                } else return false;
                              })
                              .map((linea) => (
                                <div className="tt-item" key={linea.id}>
                                  {linea.tipo_articulo !== "F" ? (
                                    <Fragment>
                                      <Link
                                        to={
                                          this.props.portal.sector_empresa !==
                                            undefined &&
                                          this.props.portal.sector_empresa !==
                                            "" &&
                                          this.props.portal.sector_empresa ===
                                            "AL"
                                            ? "/"
                                            : "/articulo/" +
                                              linea.id_articulo +
                                              "/" +
                                              linea.descripcion
                                                .replaceAll("/", " ")
                                                .replaceAll(" ", "-")
                                                .replaceAll("%", "")
                                                .toLowerCase()
                                        }
                                      >
                                        <div className="tt-item-img">
                                          {this.props.portal.sector_empresa !==
                                            undefined &&
                                          this.props.portal.sector_empresa !==
                                            "" &&
                                          this.props.portal.sector_empresa ===
                                            "AL" ? (
                                            <ImagenPerfil
                                              codigo_perfil={linea.articulo.substr(
                                                0,
                                                5
                                              )}
                                              tipo="WEB"
                                            />
                                          ) : (
					    <Imagen
                                              codigo_articulo={linea.articulo}
                                              principal="S"
                                              numero_imagen={1}
                                            />
					  )}
                                        </div>
                                        <div className="tt-item-descriptions">
                                          <h2 className="tt-title">
                                            {linea.articulo}
                                          </h2>
                                          <ul className="tt-add-info">
                                            <li>{linea.descripcion}</li>
                                            <li className="ocultar">
                                              Pesentación:{" "}
                                              {linea.presentacion_pedido}
                                            </li>
                                          </ul>
                                          <div className="tt-quantity">
                                            {linea.sub_pres !== undefined &&
                                            linea.sub_pres !== null &&
                                            linea.sub_pres !== ""
                                              ? linea.sub_pres_cant +
                                                " X " +
                                                linea.sub_pres +
                                                " X "
                                              : linea.cantidad_pedida +
                                                " (" +
                                                linea.presentacion_pedido +
                                                ") X "}
                                          </div>
                                          <div className="tt-price">
                                            <div className="ocultar">
                                              <Precio
                                                codigo_articulo={linea.articulo}
                                                precio_manual={
                                                  linea.precio_venta
                                                }
                                              />
                                            </div>
                                            {linea.tipo_linea !== "R" ? (
                                              linea.precio_manual === "S" ? (
                                                <Precio
                                                  codigo_articulo={
                                                    linea.articulo
                                                  }
                                                  precio_manual={
                                                    linea.precio_venta
                                                  }
                                                />
                                              ) : (
                                                <span>
                                                  {
                                                    <Precio
                                                      codigo_articulo={
                                                        linea.articulo
                                                      }
                                                    />
                                                  }
                                                </span>
                                              )
                                            ) : (
                                              <b> 0,00 €</b>
                                            )}
                                          </div>
                                        </div>
                                      </Link>

                                      <div className="tt-item-close">
                                        <a
                                          href="/#"
                                          onClick={this.onClick}
                                          data-linea={linea.id}
                                          className="tt-btn-close"
                                        >
                                          <span className="ocultar">
                                            Cerrar
                                          </span>
                                        </a>
                                      </div>
                                    </Fragment>
                                  ) : (
                                    <Fragment>
                                      {" "}
                                      <div className="tt-item-img">
                                        {this.props.portal.sector_empresa !==
                                          undefined &&
                                        this.props.portal.sector_empresa !==
                                          "" &&
                                        this.props.portal.sector_empresa ===
                                          "AL" ? (
                                          <ImagenPerfil
                                            codigo_perfil={linea.articulo.substr(
                                              0,
                                              5
                                            )}
                                            tipo="WEB"
                                          />
                                        ) : (
					  <Imagen
                                            codigo_articulo={linea.articulo}
                                            principal="S"
                                            numero_imagen={1}
                                          />
					)}
                                      </div>
                                      <div className="tt-item-descriptions">
                                        <h2 className="tt-title">
                                          {linea.articulo}
                                        </h2>
                                        <ul className="tt-add-info">
                                          <li>{linea.descripcion}</li>
                                          <li className="ocultar">
                                            Presentación:{" "}
                                            {linea.presentacion_pedido}
                                          </li>
                                        </ul>
                                        <div className="tt-quantity">
                                          {linea.cantidad_pedida +
                                            " (" +
                                            linea.presentacion_pedido +
                                            ") X "}
                                        </div>
                                        <div className="tt-price">
                                          {linea.precio_manual === "S" ? (
                                            <Precio
                                              codigo_articulo={linea.articulo}
                                              precio_manual={linea.precio_venta}
                                            />
                                          ) : (
                                            <span>
                                              <div className="ocultar">
                                                <Precio
                                                  codigo_articulo={
                                                    linea.articulo
                                                  }
                                                  precio_manual={
                                                    linea.precio_venta
                                                  }
                                                />
                                              </div>
                                              {" " +
                                                Intl.NumberFormat(
                                                  this.props.locale,
                                                  {
                                                    style: "currency",
                                                    currency: this.props.moneda,
                                                  }
                                                ).format(
                                                  Number.parseFloat(
                                                    linea.precio_venta /
                                                      linea.cantidad_pedida
                                                  ).toFixed(2)
                                                )}

                                              {this.props.precio[
                                                linea.articulo
                                              ] !== undefined &&
                                              !Array.isArray(
                                                this.props.precio[
                                                  linea.articulo
                                                ].data.impuesto_articulo
                                              ) &&
                                              this.props.precio[linea.articulo]
                                                .data.impuesto_articulo !== null
                                                ? " + " +
                                                  Number.parseInt(
                                                    this.props.precio[
                                                      linea.articulo
                                                    ].data.impuesto_articulo
                                                  ) +
                                                  "% IVA"
                                                : ""}
                                            </span>
                                          )}
                                        </div>
                                      </div>
                                    </Fragment>
                                  )}
                                </div>
                              ))}
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="tt-cart-btn">
                  <div className="tt-item">
                    {this.props.pedido.lineas === undefined ||
                    this.props.pedido.lineas.length === 0 ? (
                      "No hay articulos en el carrito"
                    ) : (
                      <Fragment>
                        {this.props.invitado !== undefined &&
                        this.props.invitado === true ? (
                          <Link
                            to="/login"
                            className="btn"
                            onClick={this.ocultarCarrito}
                          >
                            LOGIN
                          </Link>
                        ) : (
                          <Fragment>
                            {!this.props.pedido_cargando ? (
                              <Link
                                to="/resumen"
                                className="btn"
                                onClick={this.ocultarCarrito}
                              >
                                FINALIZAR CARRITO
                              </Link>
                            ) : (
                              ""
                            )}

                            <button
                              type="submit"
                              onClick={this.nuevoPedido}
                              className="btn btn-primary btn-nuevo-pedido"
                            >
                              NUEVO CARRITO
                            </button>
                          </Fragment>
                        )}
                      </Fragment>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  pedido: state.pedidos.pedido,
  hash: state.auth.hash,
  invitado: state.auth.invitado,
  locale: state.precio.locale,
  moneda: state.precio.moneda,
  precio: state.precio.precio,
  pedido_cargando: state.pedidos.isLoading,
  id_pedido_seleccionado: state.pedidos.id_pedido_seleccionado,
  portal: state.portal.portal,
});

export default connect(mapStateToProps, {
  deleteLinea,
  setIdPedidoSeleccionado,
  nuevoPedido,
  getPedidoActivo,
})(Carrito);
