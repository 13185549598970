import axios from "../axiosConfig";
import { tokenConfig } from "./auth";
import {
  FETCH_P_PERFILES_IMAGENES,
  GET_P_PERFILES_IMAGENES
} from "./types";

// GET P PERFILES IMAGENES
export const getImagenesPerfiles = (
    codigo_perfil = "",
    tipo = "WEB") => (dispatch, getState) => {
  dispatch({
    type: FETCH_P_PERFILES_IMAGENES,
  });

  let config = tokenConfig(getState);

  if (codigo_perfil == undefined || codigo_perfil == null) codigo_perfil = "";

  axios
    .get(`/perfilesImagenes/?codigo_perfil=${codigo_perfil}&tipo=${tipo}`, config)
    .then((res) => {
      dispatch({
        type: GET_P_PERFILES_IMAGENES,
        payload: res.data
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
