import {
  FETCH_ARTICULOS_CARRITO, 
  GET_ARTICULOS_CARRITO,
} from "../actions/types.js";

const initialState = {
  carrito_extrusion: {},
  isLoading: false,
};

export default function reducer (state = initialState, action) {
  switch (action.type) {
    case FETCH_ARTICULOS_CARRITO:
      return {
        ...state,
        carrito_extrusion: {}
      };
    case GET_ARTICULOS_CARRITO:
      return {
        ...state,
        carrito_extrusion: action.payload
      };
    default:
      return state;
  }
}