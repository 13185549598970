import {
  GET_IMAGEN,
  GET_IMAGEN_SUCCESS,
  GET_IMAGEN_FAIL,
} from "../actions/types.js";

const initialState = {
  imagenes: {},
};

export default function reducer(state = initialState, action) {
  let lista_imagenes;
  switch (action.type) {
    case GET_IMAGEN:
      lista_imagenes = state.imagenes;

      if (lista_imagenes[action.codigo_articulo] === undefined) {
        lista_imagenes[action.codigo_articulo] = {};
      }

      lista_imagenes[action.codigo_articulo][action.numero_imagen] = {
        imagen: "",
        isLoading: true,
        error: false,
      };
      return {
        ...state,
        imagenes: { ...state.imagenes, ...lista_imagenes },
      };
    case GET_IMAGEN_FAIL:
      lista_imagenes = state.imagenes;

      lista_imagenes[action.codigo_articulo][action.numero_imagen].imagen = "";
      lista_imagenes[action.codigo_articulo][
        action.numero_imagen
      ].isLoading = false;
      lista_imagenes[action.codigo_articulo][action.numero_imagen].error = true;
      return {
        ...state,
        imagenes: { ...state.imagenes, ...lista_imagenes },
      };
    case GET_IMAGEN_SUCCESS:
      lista_imagenes = state.imagenes;

      lista_imagenes[action.codigo_articulo][action.numero_imagen].imagen =
        action.payload;
      lista_imagenes[action.codigo_articulo][
        action.numero_imagen
      ].isLoading = false;

      lista_imagenes[action.codigo_articulo][
        action.numero_imagen
      ].error = false;
      return {
        ...state,
        imagenes: { ...state.imagenes, ...lista_imagenes },
      };
    default:
      return state;
  }
}
