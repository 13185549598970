import {
  SET_BUSQUEDA_EXTRUSION,
  UNSET_BUSQUEDA_EXTRUSION,
} from "../actions/types.js";

const initialState = {
  busqueda_realizada: ""
};

export default function (state = initialState, action) {
  switch (action.type) {
    case SET_BUSQUEDA_EXTRUSION:
      return {
        ...state,
        busqueda_realizada: action.payload
      };
    case UNSET_BUSQUEDA_EXTRUSION:
      return {
        ...state,
        busqueda_realizada: ""
      };
    default:
      return state;
  }
}