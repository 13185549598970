import axios from "../axiosConfig";
import { createMessage } from "./messages";
import {
  GET_TIPOS_EXPEDIENTES,
  GET_TIPOS_EXPEDIENTES_SUCCESS,
  GET_TIPOS_EXPEDIENTES_FAILURE,
  GET_TIPOS_EXPEDIENTES_CAMPOS,
  GET_EXPEDIENTES,
  GET_EXPEDIENTES_SUCCESS,
  GET_EXPEDIENTES_FAILURE,
  FETCH_TIPOS_EXPEDIENTES,
  FETCH_TIPOS_EXPEDIENTES_CAMPOS,
  FETCH_EXPEDIENTES,
  SEND_EXPEDIENTE,
  SEND_EXPEDIENTE_SUCCESS,
  SEND_EXPEDIENTE_FAILURE,
  USER_UNAUTHORIZED,
  GET_LINEAS_EXPEDIENTE,
  GET_LINEAS_EXPEDIENTE_SUCCESS,
  GET_LINEAS_EXPEDIENTE_FAILURE,
  SHOW_ALERT,
  GET_OBSERVACIONES_EXPEDIENTE,
  GET_OBSERVACIONES_EXPEDIENTE_SUCCESS,
  GET_OBSERVACIONES_EXPEDIENTE_FAILURE,
  SEND_OBSERVACIONES_EXPEDIENTE,
  SEND_OBSERVACIONES_EXPEDIENTE_SUCCESS,
  SEND_OBSERVACIONES_EXPEDIENTE_FAILURE,
  MARCAR_LEIDOS,
  MARCAR_LEIDOS_SUCCESS,
  MARCAR_LEIDOS_FAILURE,
  GET_ARCHIVED_CONVERSATIONS,
  GET_ARCHIVED_CONVERSATIONS_SUCCESS,
  GET_ARCHIVED_CONVERSATIONS_FAILURE,
  GET_DELETED_CONVERSATIONS,
  GET_DELETED_CONVERSATIONS_SUCCESS,
  GET_DELETED_CONVERSATIONS_FAILURE,
  ARCHIVE_CONVERSATION,
  DELETE_CONVERSATION,
  ARCHIVE_CONVERSATION_FAILURE,
  ARCHIVE_CONVERSATION_SUCCESS,
  DELETE_CONVERSATION_FAILURE,
  DELETE_CONVERSATION_SUCCESS,
} from "./types";

import { tokenConfig } from "./auth";

export const getTiposExpedientes = () => (dispatch, getState) => {
  dispatch({
    type: FETCH_TIPOS_EXPEDIENTES,
  });
  axios
    .get(`/tiposexpedientes/`, tokenConfig(getState))
    .then((res) => {
      dispatch({
        type: GET_TIPOS_EXPEDIENTES,
        payload: res.data,
      });
    })
    .catch((err) => console.log(err));
};

export const getTiposExpedientesCampos =
  (search = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_TIPOS_EXPEDIENTES_CAMPOS,
    });
    axios
      .get(`/tiposexpedientescampos/?search=${search}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_TIPOS_EXPEDIENTES_CAMPOS,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

export const getExpedientes =
  (search = "") =>
  (dispatch, getState) => {
    dispatch({
      type: FETCH_EXPEDIENTES,
    });
    axios
      .get(`/expedientes/?search=${search}`, tokenConfig(getState))
      .then((res) => {
        dispatch({
          type: GET_EXPEDIENTES,
          payload: res.data,
        });
      })
      .catch((err) => console.log(err));
  };

export const nuevoExpediente = (datos) => (dispatch, getState) => {
  const body = JSON.stringify({ datos: datos });

  axios
    .post(`/expedientes/`, body, tokenConfig(getState))
    .then((res) => {
      dispatch(
        createMessage({
          expedienteOK: res.data,
        })
      );
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        createMessage({
          expedienteKO: err.response.data,
        })
      );
    });
};

//NUEVOS DE LA APP
export const sendConversation = (expediente) => async (dispatch, getState) => {
  dispatch({ type: SEND_EXPEDIENTE });

  let config = tokenConfig(getState);

  let body = JSON.stringify(expediente);
  axios
    .post("/expedientes/createconversation/", body, config)
    .then((res) => {
      createMessage({
        converscionOK: res.data,
      });
      dispatch({
        type: SEND_EXPEDIENTE_SUCCESS,
        payload: { expediente: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch(
        createMessage({
          converscionKO: err.response.data,
        })
      );
    });
};

export const getLineasExpediente =
  (numero_expediente) => async (dispatch, getState) => {
    dispatch({ type: GET_LINEAS_EXPEDIENTE });

    let config = tokenConfig(getState);

    axios
      .get(`/expedienteslin/?exp=${numero_expediente}`, config)
      .then((res) => {
        dispatch({
          type: GET_LINEAS_EXPEDIENTE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const getObservacionesExpediente =
  (numero_expediente) => async (dispatch, getState) => {
    dispatch({ type: GET_OBSERVACIONES_EXPEDIENTE });

    let config = tokenConfig(getState);

    axios
      .get(`/expedienteslinobs/?exp=${numero_expediente}`, config)
      .then((res) => {
        dispatch({
          type: GET_OBSERVACIONES_EXPEDIENTE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const sendObservacionesExpediente =
  (numero_expediente, observacion) => async (dispatch, getState) => {
    dispatch({ type: SEND_OBSERVACIONES_EXPEDIENTE });

    let config = tokenConfig(getState);

    let body = JSON.stringify({
      observaciones: observacion,
      exp: numero_expediente,
    });

    axios
      .post(`/expedienteslinobs/`, body, config)
      .then((res) => {
        dispatch({
          type: SEND_OBSERVACIONES_EXPEDIENTE_SUCCESS,
          payload: { observacion_expediente: res.data },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const marcarLeidos =
  (observaciones_leidas) => async (dispatch, getState) => {
    dispatch({ type: MARCAR_LEIDOS });

    let config = tokenConfig(getState);
    let body = JSON.stringify({ mensajes_leidos: observaciones_leidas });

    await axios
      .post("/expedienteslinobs/marcarleidos/", body, config)
      .then((res) => {
        dispatch({
          type: MARCAR_LEIDOS_SUCCESS,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const deleteConversation =
  (numero_expediente) => async (dispatch, getState) => {
    dispatch({ type: DELETE_CONVERSATION });

    let config = tokenConfig(getState);

    let body = JSON.stringify({ numero_expediente: numero_expediente });

    await axios
      .post("/expedientes/borrar/", body, config)
      .then((res) => {
        dispatch({
          type: DELETE_CONVERSATION_SUCCESS,
          payload: { numero_expediente: numero_expediente },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const archiveConversation =
  (numero_expediente) => async (dispatch, getState) => {
    dispatch({ type: ARCHIVE_CONVERSATION });

    let config = tokenConfig(getState);

    let body = JSON.stringify({ numero_expediente: numero_expediente });

    await axios
      .post("/expedientes/archivar/", body, config)
      .then((res) => {
        dispatch({
          type: ARCHIVE_CONVERSATION_SUCCESS,
          payload: { numero_expediente: numero_expediente },
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

export const getArchivedConversations = () => async (dispatch, getState) => {
  dispatch({ type: GET_ARCHIVED_CONVERSATIONS });

  let config = tokenConfig(getState);

  await axios
    .get("/expedientes/archivados/", config)
    .then((res) => {
      dispatch({
        type: GET_ARCHIVED_CONVERSATIONS_SUCCESS,
        payload: { expedientes_archivados: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getDeletedConversations = () => async (dispatch, getState) => {
  dispatch({ type: GET_DELETED_CONVERSATIONS });

  let config = tokenConfig(getState);

  await axios
    .get("/expedientes/borrados/", config)
    .then((res) => {
      dispatch({
        type: GET_DELETED_CONVERSATIONS_SUCCESS,
        payload: { expedientes_borrados: res.data },
      });
    })
    .catch((err) => {
      console.log(err);
    });
};
