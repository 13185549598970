import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { getImagenesPerfiles } from "../../actions/ImagenesPerfiles"
import Spinner from "../layout/Spinner";

export class ImagenPerfil extends Component {
  static propTypes = {
    codigo_perfil: PropTypes.string.isRequired,
    tipo: PropTypes.string,
    getImagenesPerfiles: PropTypes.func.isRequired,
    imagenesPerfiles: PropTypes.array,
    cargandoImagenes: PropTypes.bool
  };

  componentDidMount() {
    if (
      (this.props.imagenesPerfiles == undefined ||
      this.props.imagenesPerfiles.length == 0) && !this.props.cargandoImagenes
    ) {
        this.props.getImagenesPerfiles(
      );
    }
  }

  componentDidUpdate(prevProps, prevState) {
  }

  render() {
    if (this.props.imagenesPerfiles.filter(imagen => imagen.codigo_perfil === this.props.codigo_perfil).length > 0) {
      if (
        this.props.imagenesPerfiles !== undefined  && this.props.imagenesPerfiles.length > 0 &&
        this.props.imagenesPerfiles.filter(imagen_perfil => imagen_perfil.codigo_perfil === this.props.codigo_perfil) == undefined &&
        this.props.imagenesPerfiles.filter(imagen_perfil => imagen_perfil.codigo_perfil === this.props.codigo_perfil && imagen_perfil.tipo === this.props.tipo) == undefined
      ) {
        return <Spinner />;
      } else if (
        this.props.imagenesPerfiles !== undefined && this.props.imagenesPerfiles.length > 0 &&
        this.props.imagenesPerfiles.filter(imagen_perfil => imagen_perfil.codigo_perfil === this.props.codigo_perfil) !== undefined &&
        this.props.imagenesPerfiles.filter(imagen_perfil => imagen_perfil.codigo_perfil === this.props.codigo_perfil && imagen_perfil.tipo === this.props.tipo) !== undefined
      ) {
        return (
          <Fragment>
            <img
              src={
                "data:image/png;base64, " +
                this.props.imagenesPerfiles.filter(imagen_perfil => imagen_perfil.codigo_perfil === this.props.codigo_perfil && imagen_perfil.tipo === this.props.tipo)[0].fichero
              }
              data-src={
		"data:image/png;base64, " +
                this.props.imagenesPerfiles.filter(imagen_perfil => imagen_perfil.codigo_perfil === this.props.codigo_perfil && imagen_perfil.tipo === this.props.tipo)[0].fichero
              }
              alt=""
            />
          </Fragment>
        );
      } else {
        return (
          <Fragment>
            <img
              src="/assets/img/placeholder.jpg"
              data-src="/assets/img/placeholder.jpg"
              alt=""
            />
          </Fragment>
        );
      }
    } else {
      return (
        <Fragment>
          <img
            src="/assets/img/placeholder.jpg"
            data-src="/assets/img/placeholder.jpg"
            alt=""
          />
        </Fragment>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  imagenesPerfiles: state.imagenesPerfiles.imagenesPerfiles,
  cargandoImagenes: state.imagenesPerfiles.isLoading
});

export default connect(mapStateToProps, {
  getImagenesPerfiles,
})(ImagenPerfil);
