import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  getTiposExpedientes,
  getExpedientes,
  nuevoExpediente,
  sendObservacionesExpediente,
  getObservacionesExpediente,
  deleteConversation,
} from "../../actions/expedientes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import NuevaConversacion from "./NuevaConversacion";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css";

import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

export class Conversaciones extends Component {
  state = {
    accion: "",
    tipo_expediente_seleccionado: "",
    is_open: false,
    mostrar_nuevo: false,
    mensajeChat: "",
  };

  static propTypes = {
    tipos_expedientes: PropTypes.array.isRequired,
    expedientes: PropTypes.array.isRequired,
    getExpedientes: PropTypes.func.isRequired,
    getTiposExpedientes: PropTypes.func.isRequired,
    nuevoExpediente: PropTypes.func.isRequired,
    portal: PropTypes.object.isRequired,
    user: PropTypes.object,
  };

  dummyDiv = document.createElement("div");

  componentDidMount() {
    this.props.getExpedientes(this.props.portal.tipo_exp_conversacion);
  }

  componentDidUpdate() {
    window.wookie();
    if (this.dummyDiv.current !== null && this.dummyDiv.current !== undefined) {
      this.dummyDiv.current.scrollIntoView({ behavior: "smooth" });
    }
  }

  nuevo = (e) => {
    e.preventDefault();
    this.setState({
      tipo_expediente_seleccionado: this.props.portal.tipo_exp_conversacion,
    });
    this.setState({ accion: "nuevo" });
    this.verNuevo(e);
  };

  cerrarModal = (e) => {
    e.preventDefault();
    this.setState({ is_open: false });
  };

  getExpediente = (id) => {
    let exp_selec = this.props.expedientes.filter((exp) => {
      return exp.numero_expediente === id;
    })[0];

    if (exp_selec !== null) {
      this.setState({
        expediente: exp_selec,
      });
    }
  };

  enviarMensaje = () => {
    if (
      this.state.expediente.numero_expediente !== undefined &&
      this.state.mensajeChat !== ""
    ) {
      this.props.sendObservacionesExpediente(
        this.state.expediente.numero_expediente,
        this.state.mensajeChat
      );

      this.setState({ mensajeChat: "" });
    }
  };

  abrirModal = (e) => {
    e.preventDefault();
    this.getExpediente(e.currentTarget.dataset.codigoExpediente);

    this.props.getObservacionesExpediente(
      e.currentTarget.dataset.codigoExpediente
    );
    this.setState({ is_open: true });
  };

  cerrarConversacion = (e) => {
    e.preventDefault();
    let exp = e.currentTarget.dataset.codigoExpediente;
    confirmAlert({
      title: "ELIMINAR CONVERSACIÓN",
      message:
        "¿Está seguro de que quiere eliminar la conversación? Esta acción no se podrá deshacer.",
      closeOnEscape: true,
      closeOnClickOutside: true,
      buttons: [
        {
          label: "Sí",
          onClick: () => this.props.deleteConversation(exp),
        },
        {
          label: "No",
        },
      ],
    });
  };

  cerrarNuevo = () => {
    this.setState({ mostrar_nuevo: false });
  };

  verNuevo = (e) => {
    e.preventDefault();
    this.setState({ mostrar_nuevo: true });
  };

  render() {
    const customStyles = {
      content: {
        top: "50%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        transform: "translate(-50%, -50%)",
        width: "35%",
        height: "70%",
      },
    };

    return (
      <Fragment>
        <div className="container">
          <div id="tt-pageContent" className="conversaciones">
            <div className="container-indent">
              <div className="container">
                <div id="tt-pageContent">
                  <div className="container-indent">
                    <div className="container">
                      <Fragment>
                        <div className="tt-collapse-block">
                          <div
                            className={
                              this.state.mostrar_nuevo == true
                                ? "tt-item active"
                                : "tt-item"
                            }
                          >
                            <div className="tt-collapse-title">
                              MIS CONVERSACIONES
                            </div>
                            <div
                              className="tt-collapse-content"
                              style={
                                this.state.mostrar_nuevo == false
                                  ? { display: "block" }
                                  : { display: "none" }
                              }
                            >
                              <div className="row">
                                <button
                                  type="submit"
                                  onClick={this.nuevo}
                                  className="btn btn-primary"
                                  style={
                                    this.state.mostrar_nuevo == true
                                      ? { display: "none" }
                                      : { float: "right" }
                                  }
                                >
                                  Nueva
                                </button>
                              </div>
                              <div className="row">
                                <div className="col-sm-12 col-md-12 col-lg-12">
                                  {this.props.expedientes !== undefined &&
                                  this.props.expedientes.length > 0 ? (
                                    <Fragment>
                                      <div className="tt-shopcart-table tipos_conversaciones_desctop row">
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                          <div className="row titulos">
                                            <div className="col-sm-12 col-md-3 col-lg-3">
                                              DESCRIPCION
                                            </div>
                                            <div className="col-sm-12 col-md-6 col-lg-6">
                                              FECHA_ALTA
                                            </div>
                                            <div className="col-sm-12 col-md-3 col-lg-3">
                                              ACCIONES
                                            </div>
                                          </div>
                                          {this.props.expedientes
                                            .sort((a, b) => {
                                              if (a.fecha_alta < b.fecha_alta) {
                                                return 1;
                                              }
                                              if (a.fecha_alta > b.fecha_alta) {
                                                return -1;
                                              }

                                              return 0;
                                            })
                                            .map((expediente) => {
                                              return (
                                                <div
                                                  className="row pedido-pendiente-row"
                                                  key={expediente.id}
                                                >
                                                  <div className="col-sm-12 col-md-3 col-lg-3">
                                                    {
                                                      expediente.descripcion_expediente1
                                                    }
                                                  </div>
                                                  <div className="col-sm-12 col-md-6 col-lg-6">
                                                    {new Date(
                                                      Date.parse(
                                                        expediente.fecha_alta
                                                      )
                                                    ).toLocaleDateString(
                                                      this.props.locale
                                                    )}
                                                  </div>
                                                  <div className="col-sm-12 col-md-3 col-lg-3">
                                                    {expediente.numero_expediente !==
                                                      undefined &&
                                                    expediente.numero_expediente !==
                                                      null ? (
                                                      <div className="row icons">
                                                        <div className="col-sm-12 col-md-3 col-lg-6">
                                                          <a
                                                            href="#"
                                                            className="btn btn-primary"
                                                            onClick={
                                                              this.abrirModal
                                                            }
                                                            data-codigo-expediente={
                                                              expediente.numero_expediente
                                                            }
                                                          >
                                                            <FontAwesomeIcon
                                                              icon={faEye}
                                                            />
                                                          </a>
                                                        </div>
                                                        <div className="col-sm-12 col-md-3 col-lg-6">
                                                          <a
                                                            href="#"
                                                            className="btn btn-primary"
                                                            onClick={
                                                              this
                                                                .cerrarConversacion
                                                            }
                                                            data-codigo-expediente={
                                                              expediente.numero_expediente
                                                            }
                                                          >
                                                            <FontAwesomeIcon
                                                              icon={faTrash}
                                                            />
                                                          </a>
                                                        </div>
                                                      </div>
                                                    ) : (
                                                      ""
                                                    )}
                                                  </div>
                                                </div>
                                              );
                                            })}
                                        </div>
                                      </div>
                                      <ReactModal
                                        isOpen={this.state.is_open}
                                        onRequestClose={this.cerrarModal}
                                        style={customStyles}
                                        portalClassName="conversacionesModal"
                                      >
                                        {this.state.expediente !== undefined ? (
                                          <div id="contenidoChat">
                                            <div className="overlay"></div>

                                            <div className="chat-title">
                                              <h1>
                                                {
                                                  this.state.expediente
                                                    .descripcion_expediente1
                                                }
                                              </h1>
                                            </div>
                                            <div className="mensajes-conversacion">
                                              <div className="row conver-row">
                                                <div className="col mio">
                                                  <p>
                                                    {
                                                      this.state.expediente
                                                        .comentarios
                                                    }
                                                  </p>
                                                </div>
                                              </div>
                                              {this.state.expediente
                                                .itemn001 !== undefined &&
                                              this.state.expediente.itemn001 !==
                                                null &&
                                              this.state.expediente.itemn001 !==
                                                0 ? (
                                                <div className="row conver-row">
                                                  <div className="col mio">
                                                    <p>
                                                      {this.state.expediente
                                                        .itemn001 + " adjuntos"}
                                                    </p>
                                                  </div>
                                                </div>
                                              ) : (
                                                ""
                                              )}

                                              {this.props.observaciones_expediente
                                                ?.sort((a, b) => {
                                                  if (
                                                    a.fecha_grabacion <
                                                    b.fecha_grabacion
                                                  ) {
                                                    return -1;
                                                  }
                                                  if (
                                                    a.fecha_grabacion >
                                                    b.fecha_grabacion
                                                  ) {
                                                    return 1;
                                                  }

                                                  return 0;
                                                })
                                                .map((observacion) => {
                                                  if (
                                                    observacion.usuario_grabacion !==
                                                    this.props.user
                                                      .usuario_libra
                                                  ) {
                                                    return (
                                                      <div
                                                        className="row conver-row"
                                                        key={
                                                          observacion.numero_linea_obs
                                                        }
                                                      >
                                                        <div className="col tuyo">
                                                          <p>
                                                            {
                                                              observacion.observaciones
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                    );
                                                  } else {
                                                    return (
                                                      <div
                                                        className="row conver-row"
                                                        key={
                                                          observacion.numero_linea_obs
                                                        }
                                                      >
                                                        <div className="col mio">
                                                          <p>
                                                            {
                                                              observacion.observaciones
                                                            }
                                                          </p>
                                                        </div>
                                                      </div>
                                                    );
                                                  }
                                                })}
                                            </div>
                                            <div className="row input-mensaje">
                                              <input
                                                value={this.state.mensajeChat}
                                                placeholder="Escribe algo..."
                                                className="mensaje"
                                                autoFocus={true}
                                                onChange={(e) => {
                                                  if (
                                                    e.currentTarget.value !==
                                                    undefined
                                                  ) {
                                                    this.setState({
                                                      mensajeChat:
                                                        e.currentTarget.value,
                                                    });
                                                  }
                                                }}
                                              ></input>
                                              <button
                                                slot="end"
                                                size="small"
                                                className="btn btn-primary boton-enviar-mensaje"
                                                onClick={(e) =>
                                                  this.enviarMensaje()
                                                }
                                              >
                                                Enviar
                                              </button>
                                              <div
                                                style={{
                                                  float: "left",
                                                  clear: "both",
                                                }}
                                                className="dummyDiv"
                                                ref={this.dummyDiv}
                                              ></div>
                                            </div>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </ReactModal>

                                      <div className="tt-shopcart-table tipos_conversaciones_mobile row">
                                        <div className="col-sm-12 col-md-12 col-lg-12">
                                          {this.props.expedientes.map(
                                            (expediente) => {
                                              return (
                                                <div
                                                  className="row pedido-pendiente-row"
                                                  key={expediente.id}
                                                >
                                                  <div className="col-12">
                                                    <div className="row element-row">
                                                      <div className="col-6 titulos">
                                                        DESCRIPCION
                                                      </div>
                                                      <div className="col-6">
                                                        {
                                                          expediente.descripcion_expediente1
                                                        }
                                                      </div>
                                                    </div>
                                                    <div className="row element-row">
                                                      <div className="col-6 titulos">
                                                        FECHA_ALTA
                                                      </div>
                                                      <div className="col-6">
                                                        {new Date(
                                                          Date.parse(
                                                            expediente.fecha_alta
                                                          )
                                                        ).toLocaleDateString(
                                                          this.props.locale
                                                        )}
                                                      </div>
                                                    </div>
                                                    <div className="row element-row">
                                                      <div className="col-6 titulos">
                                                        ACCIONES
                                                      </div>

                                                      {expediente.numero_expediente !==
                                                        undefined &&
                                                      expediente.numero_expediente !==
                                                        null ? (
                                                        <div className="col-3">
                                                          <a
                                                            href="#"
                                                            className="btn btn-primary"
                                                            onClick={
                                                              this.abrirModal
                                                            }
                                                            data-codigo-expediente={
                                                              expediente.numero_expediente
                                                            }
                                                          >
                                                            <FontAwesomeIcon
                                                              icon={faEye}
                                                            />
                                                          </a>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                      {expediente.numero_expediente !==
                                                        undefined &&
                                                      expediente.numero_expediente !==
                                                        null ? (
                                                        <div className="col-3">
                                                          <a
                                                            href="#"
                                                            className="btn btn-primary"
                                                            onClick={
                                                              this
                                                                .cerrarConversacion
                                                            }
                                                            data-codigo-expediente={
                                                              expediente.numero_expediente
                                                            }
                                                          >
                                                            <FontAwesomeIcon
                                                              icon={faTrash}
                                                            />
                                                          </a>
                                                        </div>
                                                      ) : (
                                                        ""
                                                      )}
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )}
                                        </div>
                                      </div>
                                    </Fragment>
                                  ) : (
                                    "No hay conversaciones"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Fragment>
                    </div>
                  </div>
                </div>
              </div>
              {this.state.accion !== undefined &&
              this.state.accion === "nuevo" &&
              this.state.mostrar_nuevo == true ? (
                <NuevaConversacion
                  tipo_expediente={this.state.tipo_expediente_seleccionado}
                  cerrarNuevo={this.cerrarNuevo}
                />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  tipos_expedientes: state.expedientes.tipos_expedientes,
  tipos_expedientes_campos: state.expedientes.tipos_expedientes_campos,
  expedientes: state.expedientes.expedientes,
  expediente: state.expedientes.expediente,
  observaciones_expediente: state.expedientes.observaciones_expediente,
  portal: state.portal.portal,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  getExpedientes,
  getTiposExpedientes,
  nuevoExpediente,
  sendObservacionesExpediente,
  getObservacionesExpediente,
  deleteConversation,
})(Conversaciones);
