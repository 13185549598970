import {
  FETCH_EX_SERIES_PERFILES,
  GET_EX_SERIES_PERFILES,
  SET_BUSQUEDA_SERIE,
  SET_EX_SERIE_SELECCIONADA,
  UNSET_EX_SERIE_SELECCIONADA,
  GET_MAS_SERIES,
  FETCH_MAS_SERIES
} from "../actions/types.js";

const initialState = {
  series: {
    series: [],
    series_total: 0,
    series_siguiente: "",
    series_anterior: "",
  },
  serie_seleccionada: null,
  series_pagina: 12,
  isLoading: false,
};

export default function (state = initialState, action) {
  let series_nuevo;
  let mas_series;

  switch (action.type) {
    case FETCH_MAS_SERIES:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_EX_SERIES_PERFILES:
      return {
        series: {
          series: [],
          series_total: 0,
          series_siguiente: "",
          series_anterior: "",
        },
        serie_seleccionada: state.serie_seleccionada,
        series_pagina: 12,
        isLoading: true,
      };
    case GET_EX_SERIES_PERFILES:
      series_nuevo = JSON.parse(JSON.stringify(state.series));
      series_nuevo.series = action.payload.results;
      series_nuevo.series_total = null;
      series_nuevo.series_siguiente = null;
      series_nuevo.series_anterior = null;
      return {
        ...state,
        series: series_nuevo,
        series_pagina: 12,
        isLoading: false,
      };
    case SET_BUSQUEDA_SERIE:
      return {
        ...state,
        isLoading: false,
        series: action.payload,
        serie_seleccionada: null
      };
    case SET_EX_SERIE_SELECCIONADA:
      return {
        ...state,
        serie_seleccionada: action.payload,
      };
    case UNSET_EX_SERIE_SELECCIONADA:
      return {
        ...state,
        series: {
          series: [],
          series_total: 0,
          series_siguiente: "",
          series_anterior: "",
        },
        serie_seleccionada: null
      };
    case GET_MAS_SERIES:
      mas_series = JSON.parse(JSON.stringify(state.series));
      mas_series.series = [
        ...mas_series.series,
        ...action.payload.results,
      ];
      mas_series.series_siguiente = action.payload.next;
      mas_series.series_anterior = action.payload.previous;
      return {
        ...state,
        series: mas_series,
        isLoading: false,
      };
    default:
      return state;
  }
}
