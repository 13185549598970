import {
  GET_PEDIDO_ACTIVO,
  POST_LINEA_PEDIDO,
  POST_LINEAS_PEDIDO,
  DELETE_LINEA_PEDIDO,
  INTEGRAR_PEDIDO,
  DELETE_PEDIDO,
  CERRAR_PEDIDO,
  FETCH_PEDIDO_ACTIVO,
  FETCH_PEDIDO_ACTIVO_FAIL,
  INTEGRANDO_PEDIDO,
  FETCH_TEXTOS_VENTAS,
  GET_TEXTOS_VENTAS,
  SET_ID_PEDIDO_SELECCIONADO,
  POST_PEDIDO,
  INTEGRAR_PEDIDO_FAIL,
  SET_OBSERVACIONES_PEDIDO,
  SET_FECHA_ENTREGA,
  SET_NUMERO_PEDIDO_CLIENTE,
  SET_PERSONA_PEDIDO,
  SET_NOMBRE_DOM_ENVIO_MOD,
  SET_DIRECCION_DOM_ENVIO_MOD,
  SET_LOCALIDAD_DOM_ENVIO_MOD,
  SET_ESTADO_DOM_ENVIO_MOD,
  SET_PROVINCIA_DOM_ENVIO_MOD,
  SET_COD_POSTAL_DOM_ENVIO_MOD,
  SET_EMAIL_PEDIDO,
  SET_TIPO_PORTES_DOM_ENVIO_MOD,
} from "../actions/types.js";

const initialState = {
  pedido: {},
  serie_pedido: "",
  num_pedido: "",
  numero_serie_pres: "",
  numero_pres: "",
  ejercicio: "",
  texto_venta: {},
  isLoading: false,
  id_pedido_seleccionado: "",
};

export default function reducer(state = initialState, action) {
  let ped = JSON.parse(JSON.stringify(state.pedido));

  switch (action.type) {
    case FETCH_PEDIDO_ACTIVO:
      return {
        ...state,
        isLoading: true,
      };

    case GET_PEDIDO_ACTIVO:
      return {
        ...state,
        pedido: action.payload,
        serie_pedido: "",
        num_pedido: "",
        numero_serie_pres: "",
        numero_pres: "",
        ejercicio: "",
        isLoading: false,
      };

    case POST_LINEA_PEDIDO:
      ped.lineas = [...ped.lineas, action.payload];
      return {
        ...state,
        pedido: { ...state.pedido, ped },
        isLoading: false,
      };

    case POST_LINEAS_PEDIDO:
      ped.lineas = ped.lineas.concat(action.payload);
      return {
        ...state,
        pedido: { ...state.pedido, ped },
        isLoading: false,
      };

    case DELETE_LINEA_PEDIDO:
      ped.lineas = ped.lineas.filter(
        (linea) => linea.id !== Number.parseInt(action.payload)
      );
      return {
        ...state,
        pedido: { ...state.pedido, ped },
        isLoading: false,
      };

    case INTEGRANDO_PEDIDO:
      return {
        ...state,
        isLoading: true,
      };

    case INTEGRAR_PEDIDO:
      return {
        ...state,
        pedido: action.payload,
        serie_pedido: action.payload.numero_serie,
        num_pedido: action.payload.numero_pedido,
        ejercicio: action.payload.ejercicio,
        numero_pres: action.payload.numero_pres,
        numero_serie_pres: action.payload.numero_serie_pres,
        isLoading: false,
      };

    case CERRAR_PEDIDO:
      return {
        ...state,
        pedido: {},
        serie_pedido: action.payload.numero_serie,
        num_pedido: action.payload.numero_pedido,
        ejercicio: action.payload.ejercicio,
        numero_pres: action.payload.numero_pres,
        numero_serie_pres: action.payload.numero_serie_pres,
        isLoading: false,
      };

    case DELETE_PEDIDO:
      return {
        ...state,
        pedido: {},
        isLoading: false,
      };

    case INTEGRAR_PEDIDO_FAIL:
    case FETCH_PEDIDO_ACTIVO_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case FETCH_TEXTOS_VENTAS:
      return {
        ...state,
        texto_venta: {},
        isLoading: true,
      };

    case GET_TEXTOS_VENTAS:
      return {
        ...state,
        texto_venta: action.payload,
        isLoading: false,
      };

    case SET_ID_PEDIDO_SELECCIONADO:
      return {
        ...state,
        id_pedido_seleccionado: action.payload,
      };

    case POST_PEDIDO:
      return {
        ...state,
        isLoading: false,
      };

    case SET_OBSERVACIONES_PEDIDO:
      ped.observaciones = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_FECHA_ENTREGA:
      ped.fecha_entrega = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_NUMERO_PEDIDO_CLIENTE:
      ped.pedido_cliente = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_PERSONA_PEDIDO:
      ped.persona_pedido = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_NOMBRE_DOM_ENVIO_MOD:
      ped.nombre_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_DIRECCION_DOM_ENVIO_MOD:
      ped.direccion_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_LOCALIDAD_DOM_ENVIO_MOD:
      ped.localidad_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_ESTADO_DOM_ENVIO_MOD:
      ped.estado_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_PROVINCIA_DOM_ENVIO_MOD:
      ped.provincia_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_COD_POSTAL_DOM_ENVIO_MOD:
      ped.cod_postal_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_EMAIL_PEDIDO:
      ped.email = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    case SET_TIPO_PORTES_DOM_ENVIO_MOD:
      ped.tipo_portes_dom_envio = action.payload;
      return { ...state, pedido: ped, isLoading: false };

    default:
      return state;
  }
}
