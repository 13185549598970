/* eslint-disable import/no-anonymous-default-export */
import {
  FETCH_PERFILES,
  GET_PERFILES,
  SET_BUSQUEDA_PERFIL,
  SET_PERFIL_SELECCIONADO,
  UNSET_PERFIL_SELECCIONADO,
  GET_MAS_PERFILES,
  FETCH_MAS_PERFILES
} from "../actions/types.js";

const initialState = {
  perfiles: {
    perfiles: [],
    perfiles_total: 0,
    perfiles_siguiente: "",
    perfiles_anterior: "",
  },
  perfil_seleccionado: null,
  es_articulo: null,
  perfiles_pagina: 12,
  isLoading: false,
};

export default function (state = initialState, action) {
  let perfiles_nuevo;
  let mas_perfiles;
  switch (action.type) {
    case FETCH_MAS_PERFILES:
      return {
        ...state,
        isLoading: true,
      };
    case FETCH_PERFILES:
      return {
        perfiles: {
          perfiles: [],
          perfiles_total: 0,
          perfiles_siguiente: "",
          perfiles_anterior: "",
        },
        perfil_seleccionado: state.perfil_seleccionado,
        perfiles_pagina: 12,
        isLoading: true,
      };
    case GET_PERFILES:
      perfiles_nuevo = JSON.parse(JSON.stringify(state.perfiles));
      perfiles_nuevo.perfiles = action.payload.results;
      perfiles_nuevo.perfiles_total = null;
      perfiles_nuevo.perfiles_siguiente = null;
      perfiles_nuevo.perfiles_anterior = null;
      return {
        ...state,
        perfiles: perfiles_nuevo,
        es_articulo: action.payload.es_articulo,
        perfiles_pagina: 12,
        isLoading: false,
      };
    case SET_BUSQUEDA_PERFIL:
      return {
        ...state,
        isLoading: false,
        perfiles: action.payload,
        perfil_seleccionado: null
      };
    case SET_PERFIL_SELECCIONADO:
      return {
        ...state,
        perfil_seleccionado: action.payload,
      };
    case UNSET_PERFIL_SELECCIONADO:
      return {
        ...state,
        perfiles: {
          perfiles: [],
          perfiles_total: 0,
          perfiles_siguiente: "",
          perfiles_anterior: "",
        },
        perfil_seleccionado: null,
        es_articulo: "N"
      };
    case GET_MAS_PERFILES:
      mas_perfiles = JSON.parse(JSON.stringify(state.perfiles));
      mas_perfiles.perfiles = [
        ...mas_perfiles.perfiles,
        ...action.payload.results,
      ];
      mas_perfiles.perfiles_siguiente = action.payload.next;
      mas_perfiles.perfiles_anterior = action.payload.previous;
      return {
        ...state,
        perfiles: mas_perfiles,
        isLoading: false,
      };
    default:
      return state;
  }
}
